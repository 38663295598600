.calendarcontainer{
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    text-align: center;
    /* margin: auto; */
}

.dateAvail{
    margin-left: -120px;
}

.calendarhead{
    max-width: 350px;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0px;
}
.calendarhead p{
    margin: 0;
    font-weight: bold;
    font-size:15px;
}

.daysContainer th{
    padding: 5px;
}

.myDates{
    padding: 10px;
}

.myDates a{ 
    font-weight: bold;
    text-decoration: none !important;;
}

.availDays{
    cursor: pointer;
    text-align: center;
    color: #5e5c5f;

}

.extraDays{
    cursor: default;
    background-color: transparent;
    pointer-events: none;
}

.selectedDate{
    background-color: #1136cd;
}
.selectedDate a{
    color: #ffffff;
}

.otherExtraDays{
    pointer-events: none;
    color:rgb(197, 197, 197);
}


.calendarCol{
    text-align: center;
}